import { useFlags } from 'flagsmith/react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { FeatureFlags, WebRoutes } from '@lawnstarter/customer-modules/enums';
import {
  formatTreeOrBushRemovalDescription,
  getTreeOrBushRemovalFieldData,
  MQSubmitSuccessModal,
} from '@lawnstarter/customer-modules/helpers';
import {
  getTrackingEventSource,
  useOrderServiceViewedTracking,
} from '@lawnstarter/customer-modules/hooks';
import { t } from '@lawnstarter/customer-modules/services';
import {
  orderServices_submitQuote,
  properties_currentPropertyIdSelector,
} from '@lawnstarter/customer-modules/stores/modules';
import { Button } from '@lawnstarter/ls-react-common/atoms';
import { ServiceType } from '@lawnstarter/ls-react-common/enums';
import { useApiTracking, useModal } from '@lawnstarter/ls-react-common/hooks';
import { ModalTemplate, TreeOrBushRemovalForm } from '@lawnstarter/ls-react-common/organisms';

import { OrderServiceHeader } from '@src/components';
import { useDispatch, useRouteNavigation, useSelector } from '@src/hooks';
import { errorService } from '@src/services';

import { StyledTreeOrBushRemovalFormContainer } from './styles';

import type {
  TreeOrBushRemovalFormData,
  TreeOrBushRemovalFormFieldData,
} from '@lawnstarter/ls-react-common/organisms';

export function OrderTreeOrBushRemovalScreen() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { navigate } = useRouteNavigation();

  const isUpdating = useSelector(({ orderServices }) => orderServices.loadingStatus.isUpdating);
  const propertyId = useSelector(properties_currentPropertyIdSelector);

  const QuoteSubmittedModal = useModal(ModalTemplate.ACTION_CONFIRMATION);

  const { control, handleSubmit } = useForm<TreeOrBushRemovalFormData>();
  const fieldData: TreeOrBushRemovalFormFieldData = {
    ...getTreeOrBushRemovalFieldData(),
    images: {
      ...getTreeOrBushRemovalFieldData().images,
      isUploading: false,
    },
  };

  const apiTracking = useApiTracking()!;
  const carouselAbTest = useFlags([FeatureFlags.RECOMMENDATION_CAROUSEL_AB_TEST])[
    FeatureFlags.RECOMMENDATION_CAROUSEL_AB_TEST
  ];

  const source = carouselAbTest.enabled
    ? getTrackingEventSource(location.state?.source, carouselAbTest.value)
    : undefined;
  useOrderServiceViewedTracking(apiTracking, ServiceType.TreeOrBushRemoval, errorService, source);

  const submit = async (data: TreeOrBushRemovalFormData) => {
    const { success } = await dispatch(
      orderServices_submitQuote({
        updatedFields: {
          property_id: propertyId,
          service_id: 4808,
          service_str_id: ServiceType.TreeOrBushRemoval,
          budget: data.budget,
          pendingImages: data.images,
          desiredWorkDetails: formatTreeOrBushRemovalDescription(data),
        },
        source,
      }),
    );

    if (success) {
      return QuoteSubmittedModal.show(MQSubmitSuccessModal(navigate, WebRoutes.services));
    }
  };

  return (
    <StyledTreeOrBushRemovalFormContainer>
      <OrderServiceHeader
        section={t('forms.quote.request')}
        serviceName={t('forms.treeOrBushRemoval.name')}
      />

      <TreeOrBushRemovalForm control={control} fieldData={fieldData} isUpdating={isUpdating} />

      <Button
        trackID="submit"
        testID="submit"
        mode="contained"
        onPress={handleSubmit(submit)}
        loading={isUpdating}
        disabled={isUpdating}
      >
        {t('recommendations.getQuote')}
      </Button>
    </StyledTreeOrBushRemovalFormContainer>
  );
}
